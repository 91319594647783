<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header d-flex justify-content-between">
        <div class="card-title">
          <h3 class="card-label">Thu nhập nhân sự</h3>
        </div>
        <div class="card-title">
          <div class="">
            <div class="mt-1 mx-auto">
              <div class="dropdown dropdown-inline">
                <button type="button" @click="exportDemoSalary" class="btn btn-primary font-weight-bolder"
                        :disabled="loading">
												<span class="svg-icon svg-icon-md">
													<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24"
                               version="1.1">
														<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
															<rect x="0" y="0" width="24" height="24"></rect>
															<path
                                  d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                                  fill="#000000" opacity="0.3"></path>
															<path
                                  d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                                  fill="#000000"></path>
														</g>
													</svg>
                          <!--end::Svg Icon-->
												</span>Download file mẫu
                </button>
                <button type="button" @click="onSubmit"
												class="btn btn-success font-weight-bolder ml-2"
												:disabled="disable"
                        :class="loadingClass">
                  <div>
										<i class="el-icon-loading" v-if="loadingImport"></i>
										<i v-else class="fa fa-arrow-up" aria-hidden="true"></i>
                    Upload thu nhập
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="example mb-10">
          <div class="row">
            <div class="col-md-12">
              <el-checkbox-group v-model="selections" size="small" @change="handleSelections()">

                <el-checkbox-button v-for="data in listSelection" :key="data" :label="data">{{
                    data
                  }}
                </el-checkbox-button>
              </el-checkbox-group>
            </div>
          </div>
          <div>
            <div class="input-group row mb-5 mt-5">
              <div class="col-md-3">
              </div>
              <div class="col-md-12">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form class="form-inline" @submit.prevent="handleSubmit(onSubmit)">
                    <table>
                      <tr v-for="(select,index) in selections" :key="select">
                        <td>
                          <h1 style="font-size: 12px " class="mb-0 mt-2">File {{ select }} : </h1>
                        </td>
                        <td>
                          <div class=" ml-3">
                            <ValidationProvider ref="validate_file" rules="required" name="File"
                                                v-slot="{ validate, errors }"
                                                class="">
                              <div class="upload ">
                                <div class="example-btn d-flex justify-content mt-3">
                                  <div >
                                    <UploadFilePaycheck :flagDoneUpload="flagDoneUpload" :key="select" :type="select" @uploadFile="uploadFile" @deleteFile="deleteFile"></UploadFilePaycheck>
                                  </div>
                                </div>
                              </div>
                              <div class="fv-plugins-message-container">
                                <div data-field="name" data-validator="notEmpty" class="fv-help-block">
                                  {{ errors[0] }}
                                </div>
                              </div>
                            </ValidationProvider>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  EXPORT_DEMO_SALARY, POST_PAYCHECK,
} from "@/core/services/store/user/log_human_resource.module";
import Multiselect from 'vue-multiselect';
import FileUpload from 'vue-upload-component';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import {LIST_SHEET} from "@/core/option/LogResourceOption";
import UploadFilePaycheck from "@/view/pages/auth/log_resources/UploadFilePaycheck";

const _ = require('lodash');
export default {
  name: "LogResourceIndex",
  data() {
    return {
      upload: null,
      files: [],
      disable: true,
      loading: false,
      show_process_loading: false,
      loadingClass: '',
      type: "Văn phòng Tổng",
      dataJson: [],
      LIST_SHEET: LIST_SHEET,
      listSelection: [
        "Văn phòng Tổng", "Giám đốc Văn phòng","SL Trung Tâm", "SL Tỉnh", "Sale Lương cứng", "Sale Lương mềm", "Giám đốc Khu vực"
      ],
      selections: [],
      allData: [],
      loadingImport:false,
      flagDoneUpload : false
    }
  },
  components: {
    UploadFilePaycheck,
    Multiselect, FileUpload, DatePicker
  },
  computed: {
    ...mapGetters(['percentLoadingImportSalary']),
    disableUpload(){
      return !(this.allData.length == this.selections.length) || this.selections.length == 0
    }

  },
  mounted() {
    this.setBreadcrumb();
  },
  watch: {
    selections(newValue,oldValue){
      let type = newValue.map((value)=>this.LIST_SHEET.get(value))
      if(oldValue.length>newValue.length && this.allData.length>0){
       this.allData = this.allData.filter((data)=>type.includes(data.type))
        this.disable = this.disableUpload
      }
    }
  },
  methods: {
    handleSelections(){
      // 1
      if(this.selections.includes('Giám đốc Khu vực') && !this.selections.includes('Giám đốc Khu vực chi tiết')){
        this.selections.push('Giám đốc Khu vực chi tiết')
      }
      if(!this.selections.includes('Giám đốc Khu vực')) {
        let indexDetailedAreaDirector = this.selections.findIndex((selection)=>selection === 'Giám đốc Khu vực chi tiết');
        if(indexDetailedAreaDirector>-1){
          this.selections.splice(indexDetailedAreaDirector,1)
        }
      }
      // 2
      if(this.selections.includes('Giám đốc Văn phòng') && !this.selections.includes('Giám đốc Văn phòng chi tiết')){
        this.selections.push('Giám đốc Văn phòng chi tiết')
      }
      if(!this.selections.includes('Giám đốc Văn phòng')) {
        let indexDetailedAreaDirector = this.selections.findIndex((selection)=>selection === 'Giám đốc Văn phòng chi tiết');
        if(indexDetailedAreaDirector>-1){
          this.selections.splice(indexDetailedAreaDirector,1)
        }
      }
      // 3
      if(this.selections.includes('SL Trung Tâm') && !this.selections.includes('Trung tâm chi tiết')){
        this.selections.push('Trung tâm chi tiết')
      }
      if(!this.selections.includes('SL Trung Tâm')) {
        let indexDetailedAreaDirector = this.selections.findIndex((selection)=>selection === 'Trung tâm chi tiết');
        if(indexDetailedAreaDirector>-1){
          this.selections.splice(indexDetailedAreaDirector,1)
        }
      }
      // 4
      if(this.selections.includes('SL Tỉnh') && !this.selections.includes('Tỉnh chi tiết')){
        this.selections.push('Tỉnh chi tiết')
      }
      if(!this.selections.includes('SL Tỉnh')) {
        let indexDetailedAreaDirector = this.selections.findIndex((selection)=>selection === 'Tỉnh chi tiết');
        if(indexDetailedAreaDirector>-1){
          this.selections.splice(indexDetailedAreaDirector,1)
        }
      }
    } ,
    onSubmit: function () {
      this.flagDoneUpload = ! this.flagDoneUpload
      this.disable = true
      this.allData.sort((a,b)=>{
        if ((a.type === 'gdkv'||a.type === 'sale_tinh'||a.type === 'sale_trung_tam'||a.type === 'gdvp') && (b.type !== 'gdkv'||b.type !== 'sale_tinh'||b.type !== 'sale_trung_tam'||b.type !== 'gdvp')) {
          return -1;
        }
        return 0;
      })
       this.callApi(0)
    },
    async callApi(index){
      if(this.allData.length === 0 ){
        return
      }
			this.loadingImport = true;
       return  this.$store.dispatch(POST_PAYCHECK, this.allData[index]).then(() => {
          this.$message.success(`Import thành công file ${this.selections[index]}`);
         if(index + 1 >= this.allData.length){
           this.disable = false
           this.allData = [];
           this.loadingImport = false;
           return
         }
         this.callApi(index+1)
        }).catch(()=>{
          this.$message.error(`Lỗi file ${this.selections[index]}`);
          this.disable = false
         this.loadingImport = false;
        }).finally(()=>{
        })
    },
    setBreadcrumb: function () {
      this.$store.dispatch(SET_BREADCRUMB, [
        {title: "Quản lý nhân sự"},
        {title: "Thu nhập nhân sự"}
      ]);
    },
    uploadFile(data) {
      this.allData = [...this.allData, data]
      this.disable = this.disableUpload
    },
    deleteFile(type){
     let index = this.allData.findIndex((data)=>data.type == type)
      this.allData.splice(index,1)
      this.disable = this.disableUpload
    },
    exportDemoSalary: function () {
      this.loading = true;
      this.$store.dispatch(EXPORT_DEMO_SALARY).then((res) => {
        if (!res.error) {
          this.loading = false;
          this.$bvToast.toast(res.message, {
            title: 'Xuất file',
            variant: 'success',
            solid: true
          });
        } else {
          this.loading = false;
          this.$bvToast.toast(res.message, {
            title: 'Xuất file',
            variant: 'danger',
            solid: true
          });
        }
      }).catch((e) => {
        this.loading = false;
        this.$bvToast.toast(e.data.message, {
          title: 'Xuất file',
          variant: 'danger',
          solid: true
        });
      });
    },

  }
}
</script>

<style>
.fa-minus-circle:hover {
  color: red !important;

}

.input-group i {
  font-size: 0.9rem;
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
